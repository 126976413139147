import dataV from '@jiaminghi/data-view';
<template>
  <div class="second-else">
    <h1>
      {{ title }}
    </h1>
    <div class="top">
      <dv-border-box-10>
        <div class="box">
          <h1 style="font-size: x-large">公司预算</h1>
          <div class="data">
            <div class="item" v-for="(item, index) in list" :key="index">
              <dv-border-box-10>
                <div class="block">
                  <p class="tips">{{ item.name }}</p>
                  <p class="value">{{ item.amount / 10 }}万</p>
                </div>
              </dv-border-box-10>
            </div>
          </div>
        </div>
      </dv-border-box-10>
    </div>
    <div class="bottom">
      <dv-border-box-10>
        <div class="box">
          <h1 style="font-size: x-large">公司现金</h1>

          <div class="data">
            <div class="item">
              <dv-border-box-10>
                <div class="block">
                  <p class="tips">当日支出</p>
                  <p class="value">8.6万</p>
                </div>
              </dv-border-box-10>
            </div>
            <div class="item">
              <dv-border-box-10>
                <div class="block">
                  <p class="tips">当月支出</p>
                  <p class="value">32.5万</p>
                </div>
              </dv-border-box-10>
            </div>
            <div class="item">
              <dv-border-box-10>
                <div class="block">
                  <p class="tips">当日收款</p>
                  <p class="value">6.9万</p>
                </div>
              </dv-border-box-10>
            </div>
            <div class="item">
              <dv-border-box-10>
                <div class="block">
                  <p class="tips">当月收款</p>
                  <p class="value">29.6万</p>
                </div>
              </dv-border-box-10>
            </div>
          </div>
        </div>
      </dv-border-box-10>
    </div>
  </div>
</template>
<script>
export default {
  props: ["list", "title"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.second-else {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-family: KaiTi;
  h1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3em;
    font-size: xx-large;
    font-weight: 900;
    color: rgb(43, 171, 255);
    text-align: center;
  }
  .top {
    margin-top: 10%;
    width: 100%;
    height: 38%;
    // background-color: red;
    .box {
      width: 100%;
      height: 100%;
      //   background-color: pink;
      position: relative;
      h1 {
        line-height: 3em;
      }
      .data {
        padding-top: 4em;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .item {
          width: 40%;
          height: 3em;
          font-size: larger;
          .block {
            display: flex;
            justify-content: space-around;
            align-items: center;
            line-height: 3em;

            text-align: center;
            .tips {
              width: 50%;
              text-align: center;
            }
            .value {
              width: 50%;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 48%;
    .box {
      width: 100%;
      height: 100%;
      //   background-color: pink;
      position: relative;
      h1 {
        line-height: 3em;
      }
      .data {
        padding-top: 4em;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        .item {
          width: 40%;
          height: 3em;
          margin-bottom: 2em;
          font-size: larger;
          .block {
            display: flex;
            justify-content: space-around;
            align-items: center;
            line-height: 3em;
            text-align: center;
            .tips {
              width: 50%;
              text-align: center;
            }
            .value {
              width: 50%;
              text-align: center;
            }
          }
        }
      }
    }
    // background-color: red;
  }
}
</style>
