<template>
  <div id="third" style="width: 100%; height: 100%"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      option: {
        title: {
          text: "资金信息",
          left: "center",
          textStyle: { color: "rgb(43,171,255)" },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        /*  legend: {
          data: [
            "Direct",
            "Marketing",
            "Search Engine",
            "Email",
            "Union Ads",
            "Video Ads",
            "Baidu",
            "Google",
            "Bing",
            "Others",
          ],
        }, */
        series: [
          {
            name: "Access From",
            type: "pie",
            selectedMode: "single",
            radius: [0, "30%"],
            label: {
              position: "inner",
              fontSize: 14,
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 986, name: "新签合同" },
              { value: 568, name: "分包合同" },
            ],
          },
          {
            name: "资金信息",
            type: "pie",
            radius: ["45%", "60%"],
            labelLine: {
              length: 15,
            },
            label: {
              formatter: "{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ",
              backgroundColor: "#F6F8FC",
              borderColor: "#8C8D8E",
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 22,
                  align: "center",
                },
                hr: {
                  borderColor: "#8C8D8E",
                  width: "100%",
                  borderWidth: 1,
                  height: 0,
                },
                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                },
                per: {
                  color: "#fff",
                  backgroundColor: "#4C5058",
                  padding: [3, 4],
                  borderRadius: 4,
                },
              },
            },
            data: [
              { value: 202, name: "分包合同金额" },
              { value: 1085, name: "新签合同金额" },
              { value: 120, name: "合同回款金额" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    let myChart = echarts.init(document.getElementById("third"));
    myChart.setOption(this.option);
  },
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style></style>
