<template>
  <div id="first" style="width: 100%; height: 100%"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      option: {
        title: {
          text: "人力资源图",
          left: "center",
          textStyle: { color: "rgb(43,171,255)" },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "工艺技术人员",
              "管道技术人员",
              "项目部管理人员",
              "在场分包商",
              "后勤人员",
              "其他工种",
            ],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              textStyle: {
                color: "white",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            nameTextStyle: {
              color: "white",
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              textStyle: {
                color: "white",
              },
            },
          },
        ],
        series: [
          {
            name: "人数",
            type: "bar",
            barWidth: "60%",
            data: [46, 98, 20, 80, 19, 284],
          },
        ],
      },
    };
  },
  mounted() {
    let myChart = echarts.init(document.getElementById("first"));
    myChart.setOption(this.option);
  },
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style></style>
