var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "third-else" }, [
    _c("h1", [_vm._v(_vm._s(_vm.title))]),
    _c("section", [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("dv-border-box-10", [
            _c("h1", [_vm._v("金额")]),
            _c(
              "div",
              { staticClass: "data" },
              _vm._l(_vm.list.moneyNum, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "item" },
                  [
                    _c("dv-border-box-10", [
                      _c("div", { staticClass: "box" }, [
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _c("p", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.amount) + "万元"),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("dv-border-box-10", [
            _c("h1", [_vm._v("数量")]),
            _c(
              "div",
              { staticClass: "data" },
              _vm._l(_vm.list.docNum, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "item" },
                  [
                    _c("dv-border-box-10", [
                      _c("div", { staticClass: "box" }, [
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _c("p", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.num)),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }