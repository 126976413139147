var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dv-full-screen-container",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTips,
              expression: "showTips",
            },
          ],
          staticClass: "tips",
        },
        [
          _c(
            "p",
            [
              _c("dv-border-box-10", [
                _c(
                  "span",
                  { staticClass: "close" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            _vm.showTips = false
                          },
                        },
                      },
                      [_vm._v("关闭")]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "context" }, [
                  _vm._v(" 正在规划中··· "),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm.loading
        ? _c("dv-loading", [_vm._v("加载中···")])
        : _c("div", { staticClass: "money" }, [
            _c("div", { staticClass: "top" }, [
              _c(
                "div",
                { staticClass: "first" },
                [
                  _c(
                    "div",
                    { staticClass: "btn" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "more",
                          on: {
                            click: function ($event) {
                              _vm.showTips = true
                            },
                          },
                        },
                        [_vm._v(" more ")]
                      ),
                      _c("el-button", {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-sort",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.show(0)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "dv-border-box-10",
                    [
                      !_vm.flag0
                        ? _c("First")
                        : _c("FirstElse", {
                            attrs: {
                              list: _vm.obj.peopleList,
                              title: "人力资源",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "second" },
                [
                  _c(
                    "div",
                    { staticClass: "btn" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "more",
                          on: {
                            click: function ($event) {
                              _vm.showTips = true
                            },
                          },
                        },
                        [_vm._v(" more ")]
                      ),
                      _c("el-button", {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-sort",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.show(1)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "dv-border-box-10",
                    [
                      !_vm.flag1
                        ? _c("Second")
                        : _c("SecondElse", {
                            attrs: {
                              list: _vm.obj.contract,
                              title: "财务资产",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "third" },
                [
                  _c(
                    "div",
                    { staticClass: "btn" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "more",
                          on: {
                            click: function ($event) {
                              _vm.showTips = true
                            },
                          },
                        },
                        [_vm._v(" more ")]
                      ),
                      _c("el-button", {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-sort",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.show(2)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "dv-border-box-10",
                    [
                      !_vm.flag2
                        ? _c("Third")
                        : _c("ThirdElse", {
                            attrs: {
                              list: _vm.obj.contractAmount,
                              title: "合同情况",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _c(
                "div",
                { staticClass: "first" },
                [
                  _c("dv-border-box-10", [
                    _c("section", [
                      _c(
                        "div",
                        { staticClass: "thing" },
                        [
                          _c(
                            "dv-border-box-10",
                            [
                              _c("h1", [
                                _vm._v(" 当日重大事项 "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "more",
                                    staticStyle: {
                                      float: "right",
                                      "margin-right": "1em",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showTips = true
                                      },
                                    },
                                  },
                                  [_vm._v(" more ")]
                                ),
                              ]),
                              _c("dv-scroll-board", {
                                staticStyle: {
                                  width: "90%",
                                  height: "70%",
                                  margin: "0 auto",
                                },
                                attrs: { config: _vm.config },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "meeting" },
                        [
                          _c("dv-border-box-10", [
                            _c("div", { staticClass: "data" }, [
                              _c("h1", [
                                _vm._v(" 当日会议与接待 "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "more",
                                    staticStyle: {
                                      float: "right",
                                      "margin-right": "1em",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showTips = true
                                      },
                                    },
                                  },
                                  [_vm._v(" more ")]
                                ),
                              ]),
                              _c("div", { staticClass: "join" }, [
                                _c("h1", [_vm._v("当日参会")]),
                                _c("p", [
                                  _vm._v(" 京唐钢铁项目交付会议 "),
                                  _c("br"),
                                  _vm._v(" 809会议室--9：00 "),
                                ]),
                                _c("p", [
                                  _vm._v(" 东联码头一期项目交付会议 "),
                                  _c("br"),
                                  _vm._v(" 809会议室--15：30 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "service" }, [
                                _c("h1", [_vm._v("当日接待")]),
                                _c("p", [_vm._v("银川市市长来访团")]),
                                _c("p", [_vm._v("奇虎集团高层拜访")]),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "third" },
                [
                  _c("dv-border-box-10", [
                    _c("h1", [
                      _vm._v(" 重大项目进展 "),
                      _c(
                        "span",
                        {
                          staticClass: "more",
                          staticStyle: { float: "right" },
                          on: {
                            click: function ($event) {
                              _vm.showTips = true
                            },
                          },
                        },
                        [_vm._v(" more ")]
                      ),
                    ]),
                    _c("div", { staticClass: "step" }, [
                      _c(
                        "div",
                        { staticClass: "box" },
                        [
                          _c(
                            "dv-border-box-10",
                            [
                              _c("h1", [_vm._v("京唐钢铁工程项目")]),
                              _c(
                                "el-steps",
                                {
                                  staticStyle: { "margin-left": "2em" },
                                  attrs: {
                                    "finish-status": "success",
                                    direction: "vertical",
                                    icon: "el-icon-circle-check",
                                    space: 70,
                                    active: _vm.stepList1.length,
                                    "align-center": true,
                                  },
                                },
                                _vm._l(_vm.stepList1, function (item, index) {
                                  return _c("el-step", {
                                    key: index,
                                    attrs: {
                                      title: item.name,
                                      description: item.time,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "box" },
                        [
                          _c(
                            "dv-border-box-10",
                            [
                              _c("h1", [_vm._v("东联码头工程项目")]),
                              _c(
                                "el-steps",
                                {
                                  staticStyle: { "margin-left": "2em" },
                                  attrs: {
                                    "finish-status": "success",
                                    direction: "vertical",
                                    icon: "el-icon-circle-check",
                                    space: 70,
                                    active: _vm.stepList2.length,
                                    "align-center": true,
                                  },
                                },
                                _vm._l(_vm.stepList2, function (item, index) {
                                  return _c("el-step", {
                                    key: index,
                                    attrs: {
                                      title: item.name,
                                      description: item.time,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "box" },
                        [
                          _c(
                            "dv-border-box-10",
                            [
                              _c("h1", [_vm._v("中国海油接收项目")]),
                              _c(
                                "el-steps",
                                {
                                  staticStyle: { "margin-left": "2em" },
                                  attrs: {
                                    "finish-status": "success",
                                    direction: "vertical",
                                    icon: "el-icon-circle-check",
                                    space: 70,
                                    active: _vm.stepList3.length,
                                    "align-center": true,
                                  },
                                },
                                _vm._l(_vm.stepList3, function (item, index) {
                                  return _c("el-step", {
                                    key: index,
                                    attrs: {
                                      title: item.name,
                                      description: item.time,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }