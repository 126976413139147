<template>
  <dv-full-screen-container>
    <div class="tips" v-show="showTips">
      <p>
        <dv-border-box-10>
          <span class="close">
            <el-button type="danger" @click="showTips = false">关闭</el-button>
          </span>
          <span class="context"> 正在规划中··· </span>
        </dv-border-box-10>
      </p>
    </div>
    <dv-loading v-if="loading">加载中···</dv-loading>
    <div class="money" v-else>
      <div class="top">
        <div class="first">
          <div class="btn">
            <span class="more" @click="showTips = true"> more </span>
            <el-button
              type="primary"
              @click="show(0)"
              size="mini"
              icon="el-icon-sort"
            ></el-button>
          </div>
          <dv-border-box-10>
            <First v-if="!flag0" />
            <!-- <MiniBlock :list="obj.peopleList" :title="'人力资源'" v-else /> -->
            <FirstElse v-else :list="obj.peopleList" :title="'人力资源'" />
          </dv-border-box-10>
        </div>
        <div class="second">
          <div class="btn">
            <span class="more" @click="showTips = true"> more </span>
            <el-button
              type="primary"
              @click="show(1)"
              size="mini"
              icon="el-icon-sort"
            ></el-button>
          </div>
          <dv-border-box-10>
            <Second v-if="!flag1" />
            <!-- <MiniBlock :list="obj.contract" :title="'项目合同'" v-else /> -->
            <SecondElse :list="obj.contract" :title="'财务资产'" v-else />
          </dv-border-box-10>
        </div>
        <div class="third">
          <div class="btn">
            <span class="more" @click="showTips = true"> more </span>
            <el-button
              type="primary"
              @click="show(2)"
              size="mini"
              icon="el-icon-sort"
            ></el-button>
          </div>
          <dv-border-box-10>
            <Third v-if="!flag2" />
            <!-- <MiniBlock :list="obj.contractAmount" :title="'资金信息'" v-else /> -->
            <ThirdElse :list="obj.contractAmount" :title="'合同情况'" v-else />
          </dv-border-box-10>
        </div>
      </div>
      <div class="bottom">
        <div class="first">
          <dv-border-box-10>
            <section>
              <div class="thing">
                <dv-border-box-10>
                  <h1>
                    当日重大事项
                    <span
                      class="more"
                      style="float: right; margin-right: 1em"
                      @click="showTips = true"
                    >
                      more
                    </span>
                  </h1>
                  <dv-scroll-board
                    :config="config"
                    style="width: 90%; height: 70%; margin: 0 auto"
                  />
                </dv-border-box-10>
              </div>
              <div class="meeting">
                <dv-border-box-10>
                  <div class="data">
                    <h1>
                      当日会议与接待
                      <span
                        class="more"
                        style="float: right; margin-right: 1em"
                        @click="showTips = true"
                      >
                        more
                      </span>
                    </h1>
                    <div class="join">
                      <h1>当日参会</h1>
                      <p>
                        京唐钢铁项目交付会议
                        <br />
                        809会议室--9：00
                      </p>
                      <p>
                        东联码头一期项目交付会议
                        <br />
                        809会议室--15：30
                      </p>
                    </div>
                    <div class="service">
                      <h1>当日接待</h1>
                      <p>银川市市长来访团</p>
                      <p>奇虎集团高层拜访</p>
                    </div>
                  </div>
                </dv-border-box-10>
              </div>
            </section>
          </dv-border-box-10>
        </div>
        <div class="third">
          <dv-border-box-10>
            <h1>
              重大项目进展
              <span class="more" style="float: right" @click="showTips = true">
                more
              </span>
            </h1>
            <div class="step">
              <div class="box">
                <dv-border-box-10>
                  <h1>京唐钢铁工程项目</h1>
                  <el-steps
                    finish-status="success"
                    style="margin-left: 2em"
                    direction="vertical"
                    :icon="'el-icon-circle-check'"
                    :space="70"
                    :active="stepList1.length"
                    :align-center="true"
                  >
                    <el-step
                      v-for="(item, index) in stepList1"
                      :key="index"
                      :title="item.name"
                      :description="item.time"
                    ></el-step>
                  </el-steps>
                </dv-border-box-10>
              </div>
              <div class="box">
                <dv-border-box-10>
                  <h1>东联码头工程项目</h1>
                  <el-steps
                    finish-status="success"
                    style="margin-left: 2em"
                    direction="vertical"
                    :icon="'el-icon-circle-check'"
                    :space="70"
                    :active="stepList2.length"
                    :align-center="true"
                  >
                    <el-step
                      v-for="(item, index) in stepList2"
                      :key="index"
                      :title="item.name"
                      :description="item.time"
                    ></el-step>
                  </el-steps>
                </dv-border-box-10>
              </div>
              <div class="box">
                <dv-border-box-10>
                  <h1>中国海油接收项目</h1>
                  <el-steps
                    finish-status="success"
                    style="margin-left: 2em"
                    direction="vertical"
                    :icon="'el-icon-circle-check'"
                    :space="70"
                    :active="stepList3.length"
                    :align-center="true"
                  >
                    <el-step
                      v-for="(item, index) in stepList3"
                      :key="index"
                      :title="item.name"
                      :description="item.time"
                    ></el-step>
                  </el-steps>
                </dv-border-box-10>
              </div>
            </div>
          </dv-border-box-10>
        </div>
      </div>
    </div>
  </dv-full-screen-container>
</template>
<script>
import First from "@/components/echarts/first.vue";
import Second from "@/components/echarts/second.vue";
import Third from "@/components/echarts/third.vue";
import obj from "@/mock/data.json";
import MiniBlock from "@/components/echarts/miniBlock.vue";
import FirstElse from "@/components/echarts/first-else.vue";
import SecondElse from "@/components/echarts/second-else.vue";
import ThirdElse from "@/components/echarts/third-else.vue";
export default {
  data() {
    return {
      obj: obj,
      flag0: true,
      flag1: true,
      flag2: true,
      loading: true,
      showTips: false,
      TermDepend: [
        { termName: "京唐钢铁工程项目", num: "50%", status: "超前" },
        { termName: "东联码头工程项目", num: "34%", status: "正常" },
        { termName: "中国海油接收项目", num: "26%", status: "正常" },
        { termName: "晶潜多晶硅项目", num: "1%", status: "滞后" },
        { termName: "钢材材料工程项目", num: "100%", status: "完成" },
      ],
      stepList1: [
        { name: "项目正式投产", time: "2022-02-08" },
        { name: "项目试运行", time: "2021-08-08" },
        { name: "项目预付款", time: "2021-04-08" },
        { name: "项目合同签订", time: "2021-04-08" },
      ],
      stepList2: [
        { name: "项目一期交付", time: "2022-02-08" },
        { name: "项目开工仪式", time: "2021-08-08" },
        { name: "项目预付款", time: "2021-04-08" },
        { name: "项目合同签订", time: "2021-04-08" },
      ],
      stepList3: [
        { name: "项目正式投产", time: "2022-02-08" },
        { name: "项目试运行", time: "2021-08-08" },
        { name: "项目预付款", time: "2021-04-08" },
        { name: "项目合同签订", time: "2021-04-08" },
      ],
      config: {
        rowNum: 4,
        oddRowBGC: "rgba(0,0,0,0)",
        evenRowBGC: "rgba(0,0,0,0)",
        hoverPause: false,
        waitTime: 2000,
        data: [
          [
            '<span style="font-size:25px;font-family:KaiTi;">连云港石化裂解乙烯二阶段项目实现中交',
          ],
          [
            '<span style="font-size:25px;font-family:KaiTi;">首套慌煤气制乙二醇项目成功达标达产</span>',
          ],
          [
            '<span style="font-size:25px;font-family:KaiTi;">山东裕龙岛炼化一体化项目开工</span>',
          ],
          [
            '<span style="font-size:25px;font-family:KaiTi;">晶潜多晶硅项目钢结构首次吊装顺利完成</span>',
          ],
          [
            '<span style="font-size:25px;font-family:KaiTi;">GF项目应急柴油机发电机系统成功签约</span>',
          ],
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    show(i) {
      this["flag" + i] = !this["flag" + i];
    },
  },
  computed: {},
  watch: {},
  components: { First, Second, Third, MiniBlock, FirstElse, SecondElse, ThirdElse },
};
</script>
<style lang="scss" scoped>
.tips {
  width: 100%;
  height: 100%;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  p {
    // background-color: white;
    background: radial-gradient(rgb(2, 18, 86), rgb(0, 0, 0));
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%;
    height: 30%;
    transform: translate(-50%, -50%);
    position: relative;
    .close {
      position: absolute;
      top: 1em;
      right: 1em;
    }
    .context {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: xx-large;
      font-family: KaiTi;
      font-weight: 900;
      color: rgb(43, 171, 255);
    }
  }
}
.money {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 1em;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/pageBg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
  // background-color: pink;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: KaiTi;
  .more {
    color: orange;
    cursor: pointer;
  }
  .top {
    width: 100%;
    height: 49%;
    // background-color: blue;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .first {
      width: 32%;
      height: 100%;
      position: relative;
      .btn {
        z-index: 999999;
        position: absolute;
        top: 1em;
        right: 1em;
      }
    }
    .second {
      width: 32%;
      height: 100%;
      position: relative;
      .btn {
        z-index: 999999;
        position: absolute;
        top: 1em;
        right: 1em;
      }
    }
    .third {
      width: 32%;
      height: 100%;
      position: relative;
      .btn {
        z-index: 999999;
        position: absolute;
        top: 1em;
        right: 1em;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 49%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .first {
      width: 48%;
      height: 100%;
      // background-color: rgb(2, 18, 86);
      background: radial-gradient(rgb(2, 18, 86), rgb(0, 0, 0));
      h1 {
        font-size: xx-large;
        line-height: 2.5em;
        padding-left: 2em;
        font-family: KaiTi;
      }
      section {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        .thing {
          width: 66%;
          height: 100%;
        }
        .meeting {
          width: 33%;
          height: 100%;
          .data {
            h1 {
              font-size: x-large;
              padding-left: 10px;
            }
            .join,
            .service {
              margin-top: 1em;
              h1 {
                font-size: large;
              }
              p {
                padding: 1em 2em 0;
              }
            }
          }
        }
      }
    }
    .third {
      width: 48%;
      height: 100%;
      h1 {
        font-size: xx-large;
        font-weight: 900;
        font-family: KaiTi;
        padding: 0.5em;
        text-align: center;
        box-sizing: border-box;
        color: rgb(43, 171, 255);
      }
      .step {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .box {
          width: 33%;
          height: 100%;
          h1 {
            font-size: x-large;
            font-weight: 800;
            font-family: KaiTi;
          }
          /*
          display: flex;
          justify-content: center;
          align-items: center; */
          /deep/.el-step__main {
            border-left: 0.5em solid rgb(124, 128, 251);
            margin-left: 0.5em;
          }
        }
      }
    }
  }
}
</style>
