var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "first-else" }, [
    _c("h1", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("dv-border-box-10", [
          _c("h1", { staticStyle: { "font-size": "x-large" } }, [
            _vm._v("专业技术人员"),
          ]),
          _c(
            "ul",
            { staticClass: "people" },
            _vm._l(_vm.list, function (item, index) {
              return _c("li", { key: index }, [
                _c("p", { staticClass: "peopleType" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ]),
                _c("p", { staticClass: "peopleNum" }, [
                  _vm._v(_vm._s(item.num) + "人"),
                ]),
              ])
            }),
            0
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "middle" },
      [
        _c("dv-border-box-10", [
          _c("h1", { staticStyle: { "font-size": "x-large" } }, [
            _vm._v("积分制排名前三"),
          ]),
          _c("div", { staticClass: "list" }, [
            _c(
              "div",
              { staticClass: "box" },
              [_c("dv-border-box-10", [_vm._v(" 张三---95.7分 ")])],
              1
            ),
            _c(
              "div",
              { staticClass: "box" },
              [_c("dv-border-box-10", [_vm._v(" 李四---92.4分 ")])],
              1
            ),
            _c(
              "div",
              { staticClass: "box" },
              [_c("dv-border-box-10", [_vm._v(" 王五---90.5分 ")])],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("dv-border-box-10", [
          _c("div", { staticClass: "data" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("dv-border-box-10", [
                  _c("div", { staticClass: "box" }, [
                    _c("p", { staticClass: "name" }, [_vm._v("项目经理人数")]),
                    _c("p", { staticClass: "value" }, [_vm._v("14人")]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("dv-border-box-10", [
                  _c("div", { staticClass: "box" }, [
                    _c("p", { staticClass: "name" }, [
                      _vm._v("注册工程师人数"),
                    ]),
                    _c("p", { staticClass: "value" }, [_vm._v("11人")]),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }