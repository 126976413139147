<template>
  <div class="first-else">
    <h1>
      <!-- <dv-decoration-7 style="width: 8em; height: 2em; margin: 0 auto"> -->
      {{ title }}
      <!-- </dv-decoration-7> -->
    </h1>
    <div class="top">
      <dv-border-box-10>
        <h1 style="font-size: x-large">专业技术人员</h1>
        <ul class="people">
          <li v-for="(item, index) in list" :key="index">
            <p class="peopleType">
              {{ item.name }}
            </p>
            <p class="peopleNum">{{ item.num }}人</p>
          </li>
        </ul>
      </dv-border-box-10>
    </div>
    <div class="middle">
      <dv-border-box-10>
        <h1 style="font-size: x-large">积分制排名前三</h1>
        <div class="list">
          <div class="box">
            <dv-border-box-10> 张三---95.7分 </dv-border-box-10>
          </div>
          <div class="box">
            <dv-border-box-10> 李四---92.4分 </dv-border-box-10>
          </div>
          <div class="box">
            <dv-border-box-10> 王五---90.5分 </dv-border-box-10>
          </div>
        </div>
      </dv-border-box-10>
    </div>
    <div class="bottom">
      <dv-border-box-10>
        <div class="data">
          <div class="item">
            <dv-border-box-10>
              <div class="box">
                <p class="name">项目经理人数</p>
                <p class="value">14人</p>
              </div>
            </dv-border-box-10>
          </div>
          <div class="item">
            <dv-border-box-10>
              <div class="box">
                <p class="name">注册工程师人数</p>
                <p class="value">11人</p>
              </div>
            </dv-border-box-10>
          </div>
        </div>
      </dv-border-box-10>
    </div>
  </div>
</template>
<script>
export default {
  props: ["list", "title"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.first-else {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: KaiTi;
  h1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: xx-large;
    font-weight: 900;
    font-family: KaiTi;
    color: rgb(43, 171, 255);
    text-align: center;
    line-height: 1.5em;
  }
  .top {
    width: 100%;
    height: 34%;
    margin-top: 8%;
    position: relative;
    h1 {
      line-height: 3em;
    }
    // background-color: pink;
    ul {
      //   background-color: pink;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: flex-start;
      padding-top: 4em;
      li {
        width: 48%;
        height: 2em;
        display: flex;
        justify-content: space-between;

        .peopleType {
          padding-left: 1em;
          box-sizing: border-box;
          width: 50%;
        }
        .peopleNum {
          width: 50%;
          padding-left: 2em;
          box-sizing: border-box;
        }
      }
    }
  }
  .middle {
    width: 100%;
    height: 29%;
    position: relative;
    h1 {
      line-height: 3em;
    }
    .list {
      padding-top: 4em;
      height: 3em;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .box {
        text-align: center;
        line-height: 3em;
        width: 30%;
        height: 100%;
      }
    }
    // background-color: green;
  }
  .bottom {
    width: 100%;
    height: 23%;
    position: relative;
    .data {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .item {
        width: 45%;
        height: 3em;
        .box {
          display: flex;
          justify-content: space-between;
          p {
            height: 3em;
            line-height: 3em;
            text-align: center;
          }
          .name {
            width: 70%;
          }
          .value {
            width: 30%;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
