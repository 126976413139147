<template>
  <div class="miniblock">
    <h1>
      <dv-decoration-7> &nbsp;{{ title }}&nbsp; </dv-decoration-7>
    </h1>
    <div class="block" v-for="(item, index) in list" :key="index">
      <dv-border-box-10>
        <div class="box" style="width: 100%; height: 100%">
          <div class="name">{{ item.name }}</div>
          <div class="num">
            <div class="zb" v-if="item.amount">{{ item.amount }}万元</div>
            {{ item.num }}<span v-if="typeof item.num == 'number'">人</span>
          </div>
        </div>
      </dv-border-box-10>
    </div>
  </div>
</template>
<script>
export default {
  props: ["list", "title"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.miniblock {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  h1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: x-large;
    font-weight: 900;
    font-family: KaiTi;
    color: rgb(43, 171, 255);
    text-align: center;
  }
  .block {
    width: 40%;
    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .name {
        width: 50%;
        height: 5em;
        line-height: 5em;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: transparent;
        font-weight: 900;
        font-family: KaiTi;
      }
      .num {
        width: 50%;
        .zb {
          color: orange;
          font-size: large;
          font-weight: 900;
        }
      }
    }
  }
}
</style>
