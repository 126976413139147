<template>
  <div class="third-else">
    <h1>{{ title }}</h1>
    <section>
      <div class="left">
        <dv-border-box-10>
          <h1>金额</h1>
          <div class="data">
            <div class="item" v-for="(item, index) in list.moneyNum" :key="index">
              <dv-border-box-10>
                <div class="box">
                  <p class="name">{{ item.name }}</p>
                  <p class="value">{{ item.amount }}万元</p>
                </div>
              </dv-border-box-10>
            </div>
          </div>
        </dv-border-box-10>
      </div>
      <div class="right">
        <dv-border-box-10>
          <h1>数量</h1>
          <div class="data">
            <div class="item" v-for="(item, index) in list.docNum" :key="index">
              <dv-border-box-10>
                <div class="box">
                  <p class="name">{{ item.name }}</p>
                  <p class="value">{{ item.num }}</p>
                </div>
              </dv-border-box-10>
            </div>
          </div>
        </dv-border-box-10>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  props: ["list", "title"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.third-else {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 10%;
  h1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: xx-large;
    font-weight: 900;
    font-family: KaiTi;
    color: rgb(43, 171, 255);
    text-align: center;
    line-height: 1.5em;
  }
  section {
    // padding-top: 10%;
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .left {
      width: 50%;
      height: 90%;
      h1 {
        font-size: x-large;
        line-height: 2em;
      }
      .data {
        padding-top: 3em;
        .item {
          width: 80%;
          margin: 2em auto;
          .box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              width: 50%;
              text-align: center;
              line-height: 3em;
              height: 3em;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .right {
      width: 50%;
      height: 90%;
      position: relative;

      h1 {
        font-size: x-large;
        line-height: 2em;
      }
      .data {
        padding-top: 3em;
        .item {
          width: 80%;
          margin: 2em auto;
          .box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              width: 50%;
              text-align: center;
              line-height: 3em;
              height: 3em;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
