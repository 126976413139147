var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "second-else" }, [
    _c("h1", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("dv-border-box-10", [
          _c("div", { staticClass: "box" }, [
            _c("h1", { staticStyle: { "font-size": "x-large" } }, [
              _vm._v("公司预算"),
            ]),
            _c(
              "div",
              { staticClass: "data" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "item" },
                  [
                    _c("dv-border-box-10", [
                      _c("div", { staticClass: "block" }, [
                        _c("p", { staticClass: "tips" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _c("p", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.amount / 10) + "万"),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("dv-border-box-10", [
          _c("div", { staticClass: "box" }, [
            _c("h1", { staticStyle: { "font-size": "x-large" } }, [
              _vm._v("公司现金"),
            ]),
            _c("div", { staticClass: "data" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("dv-border-box-10", [
                    _c("div", { staticClass: "block" }, [
                      _c("p", { staticClass: "tips" }, [_vm._v("当日支出")]),
                      _c("p", { staticClass: "value" }, [_vm._v("8.6万")]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("dv-border-box-10", [
                    _c("div", { staticClass: "block" }, [
                      _c("p", { staticClass: "tips" }, [_vm._v("当月支出")]),
                      _c("p", { staticClass: "value" }, [_vm._v("32.5万")]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("dv-border-box-10", [
                    _c("div", { staticClass: "block" }, [
                      _c("p", { staticClass: "tips" }, [_vm._v("当日收款")]),
                      _c("p", { staticClass: "value" }, [_vm._v("6.9万")]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("dv-border-box-10", [
                    _c("div", { staticClass: "block" }, [
                      _c("p", { staticClass: "tips" }, [_vm._v("当月收款")]),
                      _c("p", { staticClass: "value" }, [_vm._v("29.6万")]),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }