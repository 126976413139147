var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "miniblock" },
    [
      _c(
        "h1",
        [_c("dv-decoration-7", [_vm._v(" " + _vm._s(_vm.title) + " ")])],
        1
      ),
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "block" },
          [
            _c("dv-border-box-10", [
              _c(
                "div",
                {
                  staticClass: "box",
                  staticStyle: { width: "100%", height: "100%" },
                },
                [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("div", { staticClass: "num" }, [
                    item.amount
                      ? _c("div", { staticClass: "zb" }, [
                          _vm._v(_vm._s(item.amount) + "万元"),
                        ])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(item.num)),
                    typeof item.num == "number"
                      ? _c("span", [_vm._v("人")])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }